<template>
  <v-dialog
    v-model="dialogSenhas"
    @click:outside="$emit('update:dialogSenhas', false)"
    @keydown.esc="$emit('update:dialogSenhas', false)"
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="primary--text font-weight-bold">
          {{ edit ? "Editar Senha" : "Adicionar Senha" }}
        </span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogSenhas', false)">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row class="mt-4">
            <v-col class="py-0 pt-3" cols="12">
              <v-text-field
                v-model="senha.origem"
                label="Origem"
                required
                :rules="formRules"
              ></v-text-field>
            </v-col>

            <v-col class="py-0 pt-3" cols="12" md="6">
              <v-text-field
                v-model="senha.login"
                label="Login"
                required
                :rules="formRules"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 pt-3" cols="12" md="6">
              <v-text-field
                v-model="senha.senha"
                label="Senha"
                required
                :rules="formRules"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 pt-3" cols="12" md="12">
              <v-textarea
                v-model="senha.descricao"
                label="Descrição"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="edit ? editarSenha() : adicionarSenha()"
          class="primary"
          >{{ edit ? "Salvar" : "Adicionar" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postSenha, putSenha } from "@/api/gestor/senhas.js";
export default {
  name: "DialogSenhas",

  props: {
    dialogSenhas: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: String,
    },
  },

  data() {
    return {
      formRules: [inputRequired],
      loading: false,
      validForm: true,
      senha: {},
    };
  },

  methods: {
    adicionarSenha() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let senha = {};

        senha.cliente_id = this.cliente_id;
        senha.origem = this.senha.origem;
        senha.descricao = this.senha.descricao;
        senha.login = this.senha.login;
        senha.senha = this.senha.senha;

        postSenha(senha)
          .then((response) => {
            if (response.status === 201) {
              this.$emit("update:dialogSenhas", false);
              this.$toast.success(
                "Informações de senha do cliente adicionado com sucesso!"
              );
              this.$emit("fetch-senhas");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    editarSenha() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let senha = {};

        senha.cliente_id = this.cliente_id;
        senha.origem = this.senha.origem;
        senha.descricao = this.senha.descricao;
        senha.login = this.senha.login;
        senha.senha = this.senha.senha;

        putSenha(this.senha.id, senha)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("update:dialogSenhas", false);
              this.$toast.success(
                "Informações de senha do cliente adicionado com sucesso!"
              );
              this.$emit("fetch-senhas");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (this.edit) {
      this.senha = this.item;
    }
  },
};
</script>

<style></style>
